import { goBack } from 'connected-react-router';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Media from 'react-media';
import { connect } from 'react-redux';
import rpcAction from '../../../actions/rpcAction';
import closeDialogAction from '../../../actions/closeDialogAction';
import showDialogAction from '../../../actions/showDialogAction';
import * as constants from '../../../constants';
import * as sharedUtilities from '../../../utilities/sharedUtilities';
import * as storeUtilities from '../../../utilities/storeUtilities';
import OkCancelDialogContainer from '../../dialogs/OkCancelDialogContainer';
import EditEmailsDialogContainer from './dialogs/EditEmailsDialogContainer';
import SuggestedPOsPanel from './SuggestedPOsPanel';

class SuggestedPOsPanelContainer extends Component {
	constructor(props){
		super(props);
		this.state = {windowSize: undefined};
	}
	render() {
		return (
			<Media queries={{
				xs: '(min-width: 0px)',
				sm: '(min-width: 576px)',
				md: '(min-width: 768px)',
				lg: '(min-width: 992px)',
				xl: '(min-width: 1200px)'
			}}>{matches => {
					let viewPort = 'xl';
					if(matches.xs === true){
						viewPort = 'xs';
					}
					if(matches.sm === true){
						viewPort = 'sm';
					}
					if(matches.md === true){
						viewPort = 'md';
					}
					if(matches.lg === true){
						viewPort = 'lg';
					}
					if(matches.xl === true){
						viewPort = 'xl';
					}
					return (
						<SuggestedPOsPanel {...this.props}
							disablePanel={this.props.disableSuggestedPOsPanel}
							viewPort={viewPort}
						/>
					);
				}}
			</Media>
		);
	}
}

const getSelectedDropdownOption = (options, selectedID) => {
	let selectedOption;
	if(options !== undefined && options.forEach !== undefined)
	{
		options.forEach(
			option => {
				if(option.value && selectedID && (option.value.toLowerCase() === selectedID.toLowerCase())){
					selectedOption = option;
				}
			}
		);
		return selectedOption;
	}
};

const getSuggestedPos = (props) => {
	const poCount = storeUtilities.getValue([props.screenId, 'SuggestedPOs','purchaseOrders','count'], 0);
	const suggestedPos = new Map();
	for(let poIndex = 1; poIndex <= poCount; poIndex++){
		const deleted = storeUtilities.getValue([props.screenId,'SuggestedPOs','purchaseOrders', poIndex,'deleted'], 0);
		suggestedPos.set(poIndex, {'poNumber': poIndex,'deleted' : deleted});
	}
	return suggestedPos;
};
const mapStateToProps = (state, props) => {
	const selectedWhsNum = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
	const selectedWarehouseOption = getSelectedDropdownOption(storeUtilities.getRpcDropdownOptions([props.screenId, 'whsNum']), selectedWhsNum);
	//selectedWarehouse is the full warehouse object that is retrieved from the server. It is required to create the PO(s).
	const selectedWarehouse = storeUtilities.getValue([props.screenId, 'selectedWarehouse'], undefined);
	const disableSuggestedPOsPanel = storeUtilities.getValue([props.screenId, 'disableSuggestedPOsPanel'], true);
	const rowData = storeUtilities.getValue([props.screenId, props.panelId, 'rowData'], undefined);
	const reqConfirmation = storeUtilities.getValue([props.screenId, 'reqConfirmation'], false);
	const validationErrors = storeUtilities.getValidationErrors([props.screenId]);
	const failsValidation = 
	'ddlShipVia' in validationErrors
	|| 'ddlTerms' in validationErrors
	|| 'ddlPOFlag' in validationErrors
	|| ('confirmationEmailAddress' in validationErrors && reqConfirmation === true);
	const suggestedPos = getSuggestedPos(props);
	const notDeletedCount = Array.from(suggestedPos, ([name, value]) => ({name, value})).filter(po => po.value.deleted === false).length;
	const disableBtnCreatePOs = notDeletedCount <= 0 || failsValidation === true || selectedWarehouse === undefined;
	return {
		rowData: rowData === undefined ? [] : rowData,
		warehouseName: selectedWarehouseOption ? selectedWarehouseOption.name : undefined,
		disableSuggestedPOsPanel: disableSuggestedPOsPanel,
		disableBtnCreatePOs,
		disableConfirmationEmail: reqConfirmation === false,
		totalCost: storeUtilities.getValue([props.screenId, props.panelId, 'totalCost'], 0),
		suggestedPos,
		poCount: notDeletedCount 
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		onLoad: () => {
			const user = storeUtilities.getUser();
			const userEmail = user !== undefined ? user.email : '';
			storeUtilities.updateValue(dispatch, [props.screenId, 'confirmationEmailAddress'], userEmail, true, false);
			const recipients = sharedUtilities.getRecipients(props.vendorEmailInfos);
			storeUtilities.updateValue(dispatch, [props.screenId, 'recipients'], recipients);
		},
		createPurchaseOrders: () => {
			const { useWarningMessage, headerMessage, warningMessages } = getWarningMessages(props.screenId, props.panelId);
			showCreatePOsConfirmationDialog(dispatch, props.screenId, 'Are you sure you want to create these POs?', useWarningMessage ? warningMessages : [], headerMessage, () => { 
				callCreatePOs(dispatch, props); 
			});
		},
		formatCurrency:(value) => {
			return sharedUtilities.formatCurrency(value);
		},
		onSuggestedPosTotalCostChange: (costDiff) => {
			const posTotalCost = storeUtilities.getValue([props.screenId, 'SuggestedPOs', 'totalCost'], 0);
			storeUtilities.updateValue(dispatch, [props.screenId, 'SuggestedPOs', 'totalCost'], posTotalCost + costDiff, true);
		},
		editEmailsClicked: () => {	
			dispatch(showDialogAction(
				<EditEmailsDialogContainer 
					screenId={props.screenId} 
					panelId={'EditEmailsDialogPanel'}
					dialogId={'editEmailsDialog'}
					parentPath={[props.screenId]}
					path={[props.screenId]}
					onDialogClosed={()=>{
						dispatch(closeDialogAction());
					}}/>, 
				325, 
				500, 
				() => {
					dispatch(closeDialogAction());
				}));
		}
	};
};

const showCreatePOsConfirmationDialog = (dispatch, screenId, mainMessage, warningMessages, heading, callback) => {
	const warningMessage = warningMessages.map ? <ul>{warningMessages.map((message, idx) => {
		return <li key={idx}><div>{message}</div></li>;
	})}</ul> : warningMessages;
	dispatch(showDialogAction(
		<OkCancelDialogContainer
			screenId={screenId} 
			title={heading}
			message={mainMessage}
			handleOkClicked={()=>{
				dispatch(closeDialogAction());
				callback();
			}}
			hasCancelButton>
			<h5>{warningMessage}</h5>
		</OkCancelDialogContainer>,
		300, 
		500, 
		()=>{},
		true,
		undefined,
		true,
		false,
		true));
};
const getWarningMessages = (screenId) => {
	const warningsToRender = [];
	const discontinuedSkus = [];
	const inactiveSkus = [];
	const toBeDropped = [];

	const selectedRows = storeUtilities.getValue([screenId, 'selectedRows'], []);
	const purchaseOrders = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders'], {});
	const checkSKUsFunction = (po) => {
		po.rowData.forEach(row => {
			const matches = selectedRows.filter(x => x.sku === row.SKU);
			if(matches.length === 1){
				const selectedRow = matches[0];
				if(selectedRow.discontinuedSku === 'Y' && discontinuedSkus.indexOf(selectedRow.sku) === -1){
					discontinuedSkus.push(selectedRow.sku);
				}
				if(selectedRow.inactiveSku === 'Y' && inactiveSkus.indexOf(selectedRow.sku) === -1){
					inactiveSkus.push(selectedRow.sku);
				}
				if(selectedRow.toBeDropped === 'Y' && toBeDropped.indexOf(selectedRow.sku) === -1){
					toBeDropped.push(selectedRow.sku);
				}
			}
		});
	};
	Object.entries(purchaseOrders).forEach(([key, po]) => {
		if(!isNaN(key) && po !== undefined){
			checkSKUsFunction(po);
		}
	});
	if(discontinuedSkus.length > 0){
		const discontinuedSkusWarning = 'The following SKUs are DISCONTINUED: ' + discontinuedSkus.join(', ') + '.';
		if(warningsToRender.indexOf(discontinuedSkusWarning) === -1){
			warningsToRender.push(discontinuedSkusWarning);
		}
	}
	if(inactiveSkus.length > 0){
		const inactiveSkusWarning = 'The following SKUs are INACTIVE: ' + inactiveSkus.join(', ') + '.';
		if(warningsToRender.indexOf(inactiveSkusWarning) === -1){
			warningsToRender.push(inactiveSkusWarning);
		}
	}
	if(toBeDropped.length > 0){
		const toBeDroppedWarning = 'The following SKUs are set TO BE DROPPED: ' + toBeDropped.join(', ') + '.';
		if(warningsToRender.indexOf(toBeDroppedWarning) === -1){
			warningsToRender.push(toBeDroppedWarning);
		}
	}
	return {
		useWarningMessage: warningsToRender.length > 0,
		headerMessage: warningsToRender.length > 0 ? 'WARNING!' : 'Confirmation',
		warningMessages: warningsToRender
	};
};
/**
 * constuct PO array to pass to other to other systems  
 * @param {string} screenId - Screen ID where this component will appear
 */
const getPoArray = (screenId) =>{
	const poCount = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', 'count'], 0);
	let poIdx = 1;
	let poArray = [];
	for(poIdx = 1; poIdx <= poCount; poIdx++){
		const deleted = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'deleted'], true);
		if(deleted){  
			continue;  
		}
		const rowData = storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'rowData'], []);
		if(!Array.isArray(rowData) || rowData.length === 0){
			continue;
		}
		let skusArray = [];
		let rowIdx = 0;
		for(rowIdx = 0; rowIdx < rowData.length; rowIdx++){
			skusArray.push({
				sku: rowData[rowIdx].SKU,
				quantity: rowData[rowIdx].QTY,
				description: rowData[rowIdx].DESCR,
				altQty: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.CalcOrder : 0,
				orderQty: rowData[rowIdx].QTY,
				cube: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Cube : 0,
				AverageDailySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyMean : 0,
				WeeklySales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeklySales : 0,
				TotalSales: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.TotalSales : 0,
				qoh: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVQOH : 0,
				inbound: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
				available: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.LTAVAIL : 0,
				WeeksOfStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WeeksOfStock : 0,
				StandardDeviation: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DailyStdDev : 0,
				SafetyStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.SafetyStock : 0,
				ReplenishmentStock: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.ReplenishmentStock : 0,
				OrderPoint: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Trigger : 0,
				WeeksOfStockOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.WOSOH : 0,
				AvailableToday: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.BVINBOUND : 0,
				QtyToWHS: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyToWHS : 0,
				QtyFromWHS: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.QtyFromWHS : 0,
				StoreQuantityOnHand: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.STOREQTY : 0,
				PieceCount: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PieceCount : 0,
				AvailableAfterOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.AvailableAfterOrder : 0,
				OrderRatio: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.Ratio : 0,
				Intradivisional: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.IntraDivisional : 0,
				DaysToNextOrder: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.DaysToNextOrder : 0,
				PromoAvail: rowData[rowIdx].ORDERLINE ? rowData[rowIdx].ORDERLINE.PromoAvail : 0,
				NeedByDate: moment(rowData[rowIdx].NEEDBYDATE, constants.cfQueryDateFormat).format('L'),
				UserDaysToNextOrder:  0
			});
		}
		//sort array by sku value so string numerical value ascending when displayed in BV
		skusArray.sort( ( a, b ) => {
			if( a.sku < b.sku ){ return -1; }
			if( a.sku > b.sku ){ return 1; }
			return 0;
		});

		poArray.push({
			skus: skusArray,
			instructions: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'instructions'], ''), 
			shipDate: moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'shipDate'], new Date())).format('L'),
			arrivalDate: moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'arrivalDate'], new Date())).format('L'),
			// shipVia: storeUtilities.getValue([screenId, 'ddlShipVia']),
			// terms: storeUtilities.getValue([screenId, 'ddlTerms']),
			// fob: rowData[0].ORDERLINE.FOB ? rowData[0].ORDERLINE.FOB : '0',
			calcArrivalDate: moment(storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'arrivalDate'], new Date())).format('L'),
			// poFlag: storeUtilities.getValue([screenId, 'ddlPOFlag'], false),
			deliverTo: storeUtilities.getValue([screenId, 'SuggestedPOs', 'purchaseOrders', poIdx, 'deliverTo']) 
		});
	}
	return poArray;
};

/**
 * create JSON message and send to webservice to create POs and display results in confirm dialog
 * @param {Object} dispatch - Redux dispatch Object
 * @param {Object} props - Redux props Object
 */
const callCreatePOs = ( dispatch, props )  =>{
	const user = storeUtilities.getUser();
	const args = {};
	const whsNum = args.warehouse = storeUtilities.getValue([props.screenId, 'whsNum'], undefined);
	args.rtgID = user.rtgID;
	args.vendor = props.vendor;
	args.warehouse = whsNum;
	args.emailAddress = storeUtilities.getValue([props.screenId, 'confirmationEmailAddress']);
	args.emailRecipients = storeUtilities.getValue([props.screenId, 'recipients']);
	args.buyerInitials = storeUtilities.getValue([props.screenId, 'txtBuyerInitials']);
	args.marketAdj = storeUtilities.getValue([props.screenId, 'marketAdjustment'], 0);
	args.grouping = storeUtilities.getValue([props.screenId, 'merchGroup']);
	args.leadTime = storeUtilities.getValue([props.screenId, 'txtTotalDays'], 0);
	args.numSalesDays = storeUtilities.getValue([props.screenId, 'salesDays'], 0);
	args.maxCube = storeUtilities.getValue([props.screenId, 'maximizeCube'], false);
	args.poCube = storeUtilities.getValue([props.screenId, 'poCube'], 0);
	args.daystoStock = storeUtilities.getValue([props.screenId, 'daysToStock'], 0);
	args.prodLeadTime = storeUtilities.getValue([props.screenId, 'prodTime'], 0);
	args.transLeadTime = storeUtilities.getValue([props.screenId, 'transitTime'], 0);
	args.sendToFaxQueue = storeUtilities.getValue([props.screenId, 'sendToFaxQueue'], false);
	args.reqConfirmation = storeUtilities.getValue([props.screenId, 'reqConfirmation'], false);
	args.combineWhsList = storeUtilities.getValue([props.screenId, 'combineWhsList'], [whsNum]);
	args.beginSalesDate = moment(storeUtilities.getValue([props.screenId, 'beginSalesDate'], moment())).format('L');
	args.endSalesDate = moment(storeUtilities.getValue([props.screenId, 'endSalesDate'], moment())).format('L');
	args.showPromoAvail = storeUtilities.getValue([props.screenId, 'showPromoAvail'], false);
	args.userDTNO = storeUtilities.getValue([props.screenId, 'daysToNextOrder'], 0);

	args.division = props.divisions[0];

	const selectedWarehouse = storeUtilities.getValue([props.screenId, 'selectedWarehouse'], undefined);
	if(selectedWarehouse === undefined){
		sharedUtilities.showError(dispatch, 'Error', 'Warhouse data is not available. Unable to create PO.');
		return;
	}

	args.warehouseAddr1 = selectedWarehouse.ADDRESS1;
	args.warehouseCity = selectedWarehouse.CITY;
	args.warehouseState = selectedWarehouse.STATE;
	args.warehouseZip = selectedWarehouse.ZIP;
	args.warehouseBVCode = selectedWarehouse.BVCODE;

	args.orderNumber = storeUtilities.getValue([props.screenId, 'salesNo'], '');
	args.poNumber = storeUtilities.getValue([props.screenId, 'selectedPO'], '');
	args.serviceNo = storeUtilities.getValue([props.screenId, 'serviceNo'], '');
	args.nameFull = user.fullName;
	args.additionalEmails = '';

	const poArray = getPoArray(props.screenId);
	args.purchaseOrders = JSON.stringify(poArray);
	dispatch(rpcAction({
		args,
		nodeRoute: constants.nodeRoutes.IFRService,
		endpoint: 'createPartsPOsJsonBody',
		method: 'POST',
		callback: (data) => {
			const successfulJSXElements = [];
			const unsuccessfulJSXElements = [];
			let hasErrors = false;
			if(Array.isArray(data) && data.length > 0){
				let jsxElementIndex = 0;
				data.forEach(row => {
					const statusCode = parseInt(row.STATUSCODE);
					if(statusCode < 200 || statusCode > 299){
						hasErrors = true;
						unsuccessfulJSXElements.push(
							<div key={jsxElementIndex++} className={'text-muted'}>
								<Container fluid={true}>
									<Row>
										<Col xs={9} sm={9} md={9} lg={9} xl={9}>
											{row.ERROR}
										</Col>
									</Row>
								</Container>
							</div>
						);
					}
					else {
						successfulJSXElements.push(
							<div key={jsxElementIndex++} className={'text-muted'}>
								<Container fluid={true}>
									<Row>
										<Col xs={9} sm={9} md={9} lg={9} xl={9}>
											{'PO#' + (row.PONUMBER.trim && row.PONUMBER.trim() !== '') ? row.PONUMBER : ''}
										</Col>
									</Row>
								</Container>
							</div>
						);
					}
				});
				const message = 'Purchase Order' + (successfulJSXElements.length > 1 ? 's':'') + ' created';
				if(successfulJSXElements.length > 0){
					successfulJSXElements.unshift(<div key={jsxElementIndex++} className='text-muted'><h5>{message}</h5></div>);
				}
				const errorMessage = 'Error' + (unsuccessfulJSXElements.length > 1 ? 's':'');
				if(unsuccessfulJSXElements.length > 0){
					unsuccessfulJSXElements.unshift(<div key={jsxElementIndex++} className='text-muted'><h5>{errorMessage}</h5></div>);
				}
				if(successfulJSXElements.length > 1){
					successfulJSXElements.push(<br key={jsxElementIndex++} />);
				}
				dispatch(showDialogAction(
					<OkCancelDialogContainer 
						id={'purchaseOrdersCreatedDialog'}
						screenId={ props.screenId + '_informationDialog'} 
						title={'Information'}
						handleOkClicked={()=>{ 
							dispatch(goBack());
							storeUtilities.deleteValue(dispatch, [props.screenId]);
						}}
						hasCancelButton={hasErrors}
						okButtonText={'Return To Search'}
						cancelButtonText={'Close Dialog and Stay'}>
						<div>
							{successfulJSXElements}
							{unsuccessfulJSXElements}
						</div>
					</OkCancelDialogContainer>, 
					250, 
					350, 
					()=>{}));
			}
		},
		retryOnFailure: false
	}));
};

SuggestedPOsPanelContainer.propTypes = {
	screenId: PropTypes.string.isRequired,
	panelId: PropTypes.string.isRequired,
	divisions: PropTypes.arrayOf(PropTypes.string).isRequired,
	warehouseName: PropTypes.string,
	vendor: PropTypes.string,
	disableSuggestedPOsPanel: PropTypes.bool,
	excludedPOsStack: PropTypes.array,
	isDialogOpen: PropTypes.bool,
	createPurchaseOrders: PropTypes.func,
	disableBtnCreatePOs: PropTypes.bool,
	vendorEmailInfos: PropTypes.arrayOf(PropTypes.object),
	vendorEmail: PropTypes.string,
	previewInFlow: PropTypes.func,
	formatCurrencty: PropTypes.func,
	disableConfirmationEmail: PropTypes.bool,
	userEmail: PropTypes.string,
	onLoad: PropTypes.func,
	totalCost: PropTypes.number,
	suggestedPos: PropTypes.object,
	editEmailsClicked: PropTypes.func,
	recipients: PropTypes.string
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(SuggestedPOsPanelContainer);

export default connectedComponent;